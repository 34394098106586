<template>
	<validation-observer
		tag="div"
		class="cassie-vertical-md"
	>
		<SectionCard>
			<template #title>
				Personal Information Fields
				<v-tooltip
					right
				>
					<template #activator="{ on }">
						<v-icon
							right
							v-on="on"
							@click.stop="piiFieldsModalOpen = true"
						>
							mdi-information
						</v-icon>
					</template>
					<span>
						Click to expand
					</span>
				</v-tooltip>
			</template>
			<template #title-action>
				<Toggle
					:value.sync="showPII"
					:disabled="forbiddenAuthType"
					label-left
					label="Show"
				/>
			</template>
			<template #subtitle>
				Enabling this option will allow you to add fields to a Preference Page to allow a Data Subject to view or update personal information.
			</template>
			<template #body>
				<div class="cassie-vertical-md">
					<v-row
						v-if="forbiddenAuthType"
						dense
					>
						<Message type="warning">
							Your selected Authentication Type cannot be used when Personal Information Fields are enabled. Please select one of the following Authentication Types on the Details Tab.
							<ul>
								<li>
									Confirm Value
								</li>
								<li>
									Prove Email
								</li>
								<li>
									Provide and Prove Email
								</li>
							</ul>
						</Message>
					</v-row>
					<v-row
						v-if="forbiddenAuthType && contactDetailsFields.length >= 1"
						dense
					>
						<v-col cols="4">
							<DeleteButton @click="removeAllExistingFields">
								Remove Existing Fields
							</DeleteButton>
						</v-col>
					</v-row>
					<v-row
						v-if="userFullPermissions && showPII && !forbiddenAuthType"
						dense
					>
						<v-col cols="6">
							<Dropdown
								v-model="selectedContactDetailsFields"
								label="Fields"
								:items="availableContactDetailsFields"
								custom-sort
								multiple
								select-all
								@change="updateSelectAllChecked"
							>
								<template
									v-if="showSelectAll"
									#prepend-item
								>
									<v-list-item
										dense
										@click="toggleSelectAll"
									>
										<v-checkbox
											v-model="selectAllChecked"
											dense
										/>
										<v-list-item-title class="pb-2">
											Select All
										</v-list-item-title>
									</v-list-item>
									<v-divider />
								</template>
							</Dropdown>
						</v-col>
						<v-col cols="3">
							<PrimaryActionButton
								@click="addContactDetailsFields"
							>
								Add
							</PrimaryActionButton>
						</v-col>
					</v-row>
					<DataTable
						v-if="showPII"
						:headers="tableHeaders"
						:items="contactDetailsFields"
						:items-per-page="50"
						hide-default-footer
					>
						<template #item.displayName="{ item, index }">
							<validation-provider
								#default="{ errors }"
								rules="required"
								mode="aggressive"
							>
								<TextField
									:value="item.displayName"
									label="Display Name *"
									:disabled="!userFullPermissions || forbiddenAuthType"
									:error-messages="errors"
									small
									:rules="{required: true, max: 200}"
									class="my-1"
									@input="updateContactDetailsRow(index, 'displayName', $event)"
								/>
							</validation-provider>
						</template>
						<template #item.readOnly="{ item, index }">
							<Toggle
								:value.sync="item.readOnly"
								:disabled="!userFullPermissions || item.mandatory || readOnlyField(item) || forbiddenAuthType"
								@update:value="updateContactDetailsRow(index, 'readOnly', $event)"
							/>
						</template>
						<template #item.mandatory="{ item, index }">
							<Toggle
								:value.sync="item.mandatory"
								:disabled="!userFullPermissions || item.readOnly || forbiddenAuthType"
								@update:value="updateContactDetailsRow(index, 'mandatory', $event)"
							/>
						</template>
						<template #item.displayOrder="{ index }">
							{{ index + 1 }}
						</template>
						<template #item.action="{ index, item }">
							<IconButton
								v-if="index !== 0 && userFullPermissions && !forbiddenAuthType"
								@click="moveFieldUp(index)"
							>
								mdi-arrow-up
							</IconButton>
							<IconButton
								v-if="index !== contactDetailsFields.length - 1 && userFullPermissions && !forbiddenAuthType"
								@click="moveFieldDown(index)"
							>
								mdi-arrow-down
							</IconButton>
							<IconButton
								tooltip-text="Restore Default Display Text"
								@click="restoreDefaultDisplayText(index, item)"
							>
								mdi-refresh
							</IconButton>
							<IconButton
								v-if="userFullPermissions"
								@click="removeContactDetailsField(index)"
							>
								mdi-trash-can
							</IconButton>
						</template>
					</DataTable>
				</div>
			</template>
		</SectionCard>
		<PiiFieldsHelperModal
			v-if="piiFieldsModalOpen"
			@close="piiFieldsModalOpen = false"
		/>
	</validation-observer>
</template>
<script>
import { mapGetters } from 'vuex'
import SectionCard from '../../../../../../shared/components/section-card.vue'
import Dropdown from '../../../../../../shared/components/dropdown.vue'
import PrimaryActionButton from '../../../../../../shared/components/primary-action-button.vue'
import DeleteButton from '../../../../../../shared/components/delete-button.vue'
import DataTable from '../../../../../../shared/components/data-table.vue'
import IconButton from '../../../../../../shared/components/icon-button.vue'
import TextField from '../../../../../../shared/components/text-field.vue'
import Toggle from '../../../../../../shared/components/toggle.vue'
import Message from '../../../../../../shared/components/message.vue'
import PiiFieldsHelperModal from './pii-fields-helper-modal.vue'
import { LINKS_AREA_FULL_PERMISSIONS } from '../../../../../../shared/permissions/admin-portal-permissions.js'
import { DIRECT_AUTHENTICATION_TYPE, PROVIDE_EMAIL_AUTHENTICATION_TYPE } from '../link-authentication-types.js'
export default {
	components: {
		SectionCard,
		Dropdown,
		PrimaryActionButton,
		DataTable,
		IconButton,
		TextField,
		Toggle,
		Message,
		DeleteButton,
		PiiFieldsHelperModal
	},
	props: {
		contactDetailsFields: {
			type: Array,
			default: () => []
		},
		linkAuthTypes: {
			type: Array,
			default: () => []
		},
		lookupDataContactFields: {
			type: Array,
			default: () => []
		},
		authenticationDetails: Object
	},
	data () {
		return {
			DIRECT_AUTHENTICATION_TYPE,
			PROVIDE_EMAIL_AUTHENTICATION_TYPE,
			showPII: false,
			selectedContactDetailsFields: [],
			selectAllChecked: false,
			piiFieldsModalOpen: false
		}
	},
	computed: {
		...mapGetters('auth', ['productAreaPermission', 'userHasUniversalBrand']),
		availableContactDetailsFields () {
			return this.lookupDataContactFields.map(({ id, defaultDisplayName }) => ({
				value: id, text: defaultDisplayName
			})).filter(({ value }) => {
				return !this.contactDetailsFields.find(({ contactFieldId }) => contactFieldId === value)
			})
		},
		showSelectAll () {
			return this.availableContactDetailsFields.length > 1
		},
		tableHeaders () {
			return [
				{ text: 'Display Name', value: 'displayName' },
				{ text: 'Read Only', value: 'readOnly' },
				{ text: 'Mandatory', value: 'mandatory' },
				{ text: 'Display Order', value: 'displayOrder' },
				{ text: 'Action', value: 'action' }
			]
		},
		userFullPermissions () {
			if (this.brandId === 0 && !this.userHasUniversalBrand) {
				return false
			}
			return this.productAreaPermission(LINKS_AREA_FULL_PERMISSIONS)
		},
		forbiddenAuthType () {
			return this.authenticationDetails.authTypeId === DIRECT_AUTHENTICATION_TYPE ||
				this.authenticationDetails.authTypeId === PROVIDE_EMAIL_AUTHENTICATION_TYPE
		}
	},
	created () {
		if (this.contactDetailsFields.length >= 1) {
			this.showPII = true
		}
	},
	methods: {
		toggleSelectAll () {
			if (this.selectedContactDetailsFields.length === this.availableContactDetailsFields.length) {
				this.selectAllChecked = false
				this.selectedContactDetailsFields = []
			} else {
				this.selectAllChecked = true
				this.selectedContactDetailsFields = this.availableContactDetailsFields.map(contactField => {
					return contactField.value
				})
			}
		},
		updateSelectAllChecked () {
			if (this.selectedContactDetailsFields.length !== this.availableContactDetailsFields.length) {
				this.selectAllChecked = false
			} else {
				this.selectAllChecked = true
			}
		},
		addContactDetailsFields () {
			const selectedContactFields = this.selectedContactDetailsFields.map(contactField => ({
				contactFieldId: contactField,
				displayName: this.lookupDataContactFields.find(({ id }) => id === contactField).defaultDisplayName,
				readOnly: contactField === 1,
				mandatory: false
			}))
			this.updateContactDetailFields([
				...this.contactDetailsFields,
				...selectedContactFields
			])
			this.selectedContactDetailsFields = []
		},
		updateContactDetailFields (contactDetailsFields) {
			this.$emit('update:contactDetailsFields', contactDetailsFields)
		},
		moveFieldDown (index) {
			this.move(this.contactDetailsFields, index, index + 1)
		},
		moveFieldUp (index) {
			this.move(this.contactDetailsFields, index, index - 1)
		},
		applySort (array) {
			this.updateContactDetailFields(array)
		},
		move (array, from, to) {
			const arrayCopy = JSON.parse(JSON.stringify(array))
			arrayCopy.splice(to, 0, arrayCopy.splice(from, 1)[0])
			return this.applySort(arrayCopy)
		},
		removeContactDetailsField (index) {
			const contactDetailsFields = [...this.contactDetailsFields]
			this.$delete(contactDetailsFields, index)
			this.updateContactDetailFields(contactDetailsFields)
		},
		updateContactDetailsRow (index, property, value) {
			const contactDetailsFields = JSON.parse(JSON.stringify(this.contactDetailsFields))
			contactDetailsFields[index][property] = value
			this.updateContactDetailFields(contactDetailsFields)
		},
		updateAuthenticationDetails (authenticationDetails) {
			this.$emit('update:authenticationDetails', authenticationDetails)
		},
		readOnlyField (field) {
			return field.contactFieldId === 1 // syrenisId currently but this may change
		},
		removeAllExistingFields () {
			this.updateContactDetailFields([])
			this.showPII = false
		},
		restoreDefaultDisplayText (index, field) {
			const contactField = this.lookupDataContactFields.find(({ id }) => id === field.contactFieldId)
			this.updateContactDetailsRow(index, 'displayName', contactField.defaultDisplayName)
		}
	}
}
</script>
