<template>
	<ManageLinkLayout
		:current-step.sync="currentStep"
		:steps.sync="steps"
		:is-edit="isEdit"
		:is-enabled="link.isEnabled"
		:link-types="lookUpData.linkTypes"
		:link-type="linkType"
		:back-button-action="goBackToOverview"
		:finish-button-action="finish"
		:link-has-changed="linkHasChanged"
		:brand-id="isEdit ? linkToEdit.brandId : userBrands[0]"
		:link-name="link.linkName"
		:link="link"
	>
		<template #content>
			<PreferenceLink
				:link.sync="link"
				:step="currentStep"
				:steps="steps"
				:look-up-data="lookUpData"
				:link-type="linkType"
				:embed-code="embedCode"
				:new-link-id="newLinkId"
				:assigned-language-items="assignedLanguageItems"
				:clone-mode="cloneMode"
				@reload-look-up-data="loadLinks"
				@extended-prefs-changed="extendedPrefsChanged"
			/>
		</template>
	</ManageLinkLayout>
</template>
<script>
import { mapGetters } from 'vuex'
import PreferenceLink from './preference-link.vue'
import { CONSENT_COLLECTION, PREFERENCE_PAGE_LINKS,	ACTION_LINKS } from '../../../../router/route-names.js'
import { getLinks, upsertActionLink, upsertPreferencePageLink } from '../../../../../../shared/utils/api/admin-portal/links/links.js'
import ManageLinkLayout from '../manage-link-layout.vue'
import { showSnackbar } from '../../../../../../shared/state/snackbar.js'
import { brandOptions, defaultBrandInSelect } from '../../../../../../shared/state/brands.js'
import { updatePrivacyPolicies } from '../../../../../../shared/state/privacy-policies.js'
import { linkTypes } from './link-types.js'

export default {
	components: { ManageLinkLayout, PreferenceLink },
	props: {
		linkToEdit: Object,
		linkType: {
			type: Number,
			required: true
		},
		assignedLanguageItems: {
			type: Array,
			default: () => []
		},
		cloneMode: {
			type: Boolean,
			default: false
		},
		accessedViaHub: {
			type: Boolean,
			default: false
		}
	},
	setup () {
		return {
			brandOptions,
			updatePrivacyPolicies,
			defaultBrandInSelect
		}
	},
	data () {
		return {
			currentStep: 0,
			link: {
				...(this.linkToEdit || { linkFields: [{}], customPrivacyPolicies: [], brandId: defaultBrandInSelect.value })
			},
			lookUpData: {},
			actionLinks: {},
			preferencePageLinks: {},
			embedCode: '',
			newLinkId: null,
			currentLinkType: null,
			extendedPreferencesChanged: false
		}
	},
	computed: {
		...mapGetters('auth', ['userBrands']),
		isEdit () {
			return !!this.linkToEdit
		},
		linkHasChanged () {
			return JSON.stringify(this.linkToEdit) !== JSON.stringify(this.link) || this.extendedPreferencesChanged || this.cloneMode
		},
		steps () {
			const preferencePageLink = this.linkType === linkTypes.preferencePageLink
			const steps = [
				{ slot: 0, title: 'Details', stepNumber: '01', stepComplete: false },
				{ slot: 1, title: 'Fields', stepNumber: '02', stepComplete: false },
				{ slot: 2, title: 'Preferences', stepNumber: '03', stepComplete: false },
				{ slot: 3, title: 'Personal Information Fields', stepNumber: '04', stepComplete: false },
				{ slot: !preferencePageLink ? 3 : 4, title: 'Properties', stepNumber: !preferencePageLink ? '04' : '05', stepComplete: false },
				{ slot: !preferencePageLink ? 4 : 5, title: 'Outcomes', stepNumber: !preferencePageLink ? '05' : '06', stepComplete: false },
				{ slot: !preferencePageLink ? 5 : 6, title: 'Themes', stepNumber: !preferencePageLink ? '06' : '07', stepComplete: false },
				{ slot: !preferencePageLink ? 6 : 7, title: 'Link', stepNumber: !preferencePageLink ? '07' : '08', stepComplete: false }
			]
			return preferencePageLink ? steps : steps.filter(({ title }) => title !== 'Personal Information Fields')
		}
	},
	async created () {
		const DEFAULT_BRAND = 0
		await this.loadLinks()
		this.currentLinkType = this.$route.query.linkType
		if (!this.isEdit) {
			this.link.brandId = DEFAULT_BRAND
			if (this.brandOptions.length === 1) {
				this.link.brandId = this.brandOptions[0]?.value ?? DEFAULT_BRAND
			}
		} else {
			if (this.currentLinkType === linkTypes.actionLink) {
				this.embedCode = this.linkToEdit.actionLinkEmbedCode
			} else if (this.currentLinkType === linkTypes.preferencePageLink) {
				this.embedCode = this.linkToEdit.preferencePageLinkEmbedCode
			}
		}
		updatePrivacyPolicies()
	},
	methods: {
		async loadLinks () {
			const { data: { lookUpData, actionLinks, preferencePageLinks } } = await getLinks()
			this.lookUpData = lookUpData
			this.actionLinks = actionLinks
			this.preferencePageLinks = preferencePageLinks
		},
		goBackToOverview () {
			if (this.accessedViaHub) {
				this.$router.push(CONSENT_COLLECTION)
			} else {
				this.$router.push({
					name: this.currentLinkType === linkTypes.actionLink ? ACTION_LINKS : PREFERENCE_PAGE_LINKS
				})
			}
		},
		extendedPrefsChanged () {
			this.extendedPreferencesChanged = true
		},
		async setNewLinkEmbedCode () {
			await this.loadLinks()

			if (this.currentLinkType === linkTypes.actionLink) {
				const linkSnippet = this.actionLinks.find(link => link.linkId === this.newLinkId)?.actionLinkEmbedCode
				this.embedCode = linkSnippet
			} else if (this.currentLinkType === linkTypes.preferencePageLink) {
				const linkSnippet = this.preferencePageLinks.find(link => link.linkId === this.newLinkId)?.preferencePageLinkEmbedCode
				this.embedCode = linkSnippet
			}
		},
		async finish () {
			if (this.link.linkFields.filter(({ isEncrypted }) => isEncrypted).length === 0) {
				this.link.configurationOptions.encryption = {
					encryptionKeyId: 0,
					encryptionKeyName: null
				}
			}
			if (this.currentLinkType === linkTypes.actionLink) {
				if (this.$route.params.linkToEdit) {
					this.link.actionLinkId = this.$route.params.linkToEdit.linkId
				}
				await upsertActionLink(this.link).then(response => {
					if (!this.isEdit || this.cloneMode) {
						this.newLinkId = response.data.newActionLinkId
						this.setNewLinkEmbedCode()
					}
				})
			} else if (this.currentLinkType === linkTypes.preferencePageLink) {
				if (this.$route.params.linkToEdit) {
					this.link.preferencePageLinkId = this.$route.params.linkToEdit.linkId
				}
				if (this.link.contactDetailsFields) {
					this.link.contactDetailsFields = this.link.contactDetailsFields.map((contactField, index) => ({
						...contactField,
						displayOrder: index + 1
					}))
				}
				await upsertPreferencePageLink(this.link).then(response => {
					if (!this.isEdit || this.cloneMode) {
						this.newLinkId = response.data.newPreferencePageLinkId
						this.setNewLinkEmbedCode()
					}
				})
				if (this.link.preferencePageLinkConfig.extendedPrefs) {
					let linkWithNewExtendedPreferenceDates = this.link.preferencePageLinkConfig.extendedPrefs
					linkWithNewExtendedPreferenceDates = linkWithNewExtendedPreferenceDates.map(extPref => ({
						...extPref,
						dateCreated: extPref.dateCreated ? extPref.dateCreated : new Date()
					}))
					this.link.preferencePageLinkConfig.extendedPrefs = linkWithNewExtendedPreferenceDates
				}
			}
			showSnackbar(`This link has been ${this.isEdit ? 'updated' : 'added'}`)
		}
	}
}
</script>
