<template>
	<validation-observer
		#default="{ reset }"
		tag="div"
		class="cassie-vertical-md"
	>
		<SectionCard>
			<template #title>
				Preference
			</template>
			<template #title-action>
				<Dropdown
					v-if="showTranslations"
					v-model="translationLanguageId"
					:items="availableLanguages"
					custom-sort
					label="Show Available Translations in"
				/>
			</template>
			<template #body>
				<p>
					Select the {{ 'Channel' | useLabels }} that you want this link to set a Preference for and the Text for the link itself.
				</p>

				<Dropdown
					:value="selectedChannelId"
					:items="channelOptions"
					:disabled="!userFullPermissions"
					custom-sort
					item-disabled="disabled"
					:label="'Channel *' | useLabels"
					tooltip-text="Select the Cassie Channel to add to this Preference Page"
					rules="required"
					class="cassie-input-width-md"
					@input="value => {
						reset()
						channelId = value
						updateActionLinkConfig(null)
					}"
				/>
				<Dropdown
					:value="actionLinkStatementId"
					:items="statementOptions"
					:disabled="!selectedChannelId || !userFullPermissions"
					:label="'Statement Text *' | useLabels"
					tooltip-text="Select one of the Statements associated to the Channel to display on this Preference Page"
					rules="required"
					class="cassie-input-width-md"
					@input="updateStatementId"
				/>
				<TextField
					v-if="actionLinkStatementId"
					:disabled="!userFullPermissions"
					:value="actionLinkStatementIsOptIn ? 'Opt In' : 'Opt Out'"
					:label="'Statement Type' | useLabels"
					tooltip-text="The Type of Statement can be set as 'Opt In' or 'Opt Out' based on the text in that Statement.  The Statement Type can be set within the Statements Module."
					view-only
					class="cassie-input-width-md"
				/>
				<Toggle
					v-if="isDoubleOptInChannel"
					:value="enforceDoubleOptIn"
					label="Enforce Double Opt In?"
					tooltip-text="Enabling this functionality will require a double opt-in confirmation via email."
					@update:value="toggleDoubleOptIn"
				/>
			</template>
		</SectionCard>
		<ActionLinkExtendedPreferencesCard
			v-if="selectedChannelId"
			:selected-channel="selectedChannelId"
			:channels="channels"
			:extended-preferences.sync="actionLinkExtendedPreferences"
			:user-full-permissions="userFullPermissions"
			@update-extended-preferences="updateExtendedPreferences"
		/>
	</validation-observer>
</template>

<script>
import { mapGetters } from 'vuex'
import { format } from 'date-fns'
import SectionCard from '../../../../../shared/components/section-card.vue'
import Dropdown from '../../../../../shared/components/dropdown.vue'
import Toggle from '../../../../../shared/components/toggle.vue'
import TextField from '../../../../../shared/components/text-field.vue'
import ActionLinkExtendedPreferencesCard from './action-link-extended-preferences-card.vue'
import sortParentAndChildChannelsMixin from '../../../../../shared/mixins/sort-parent-and-child-channels.js'
import { getPreferenceChannels } from '../../../../../shared/utils/api/channels.js'
import { LINKS_AREA_FULL_PERMISSIONS } from '../../../../../shared/permissions/admin-portal-permissions.js'
import { clientMultiBranded } from '../../../../../shared/state/brands.js'
import { DATE_FORMAT } from '../../../../../shared/utils/date-formatting.js'

export default {
	components: { TextField, Dropdown, Toggle, SectionCard, ActionLinkExtendedPreferencesCard },
	mixins: [sortParentAndChildChannelsMixin],
	props: {
		brandId: Number,
		actionLinkConfig: Object,
		enforceDoubleOptIn: Boolean
	},
	setup () {
		return {
			clientMultiBranded
		}
	},
	data () {
		return {
			channels: [],
			channelId: null,
			translationLanguageId: 0
		}
	},
	computed: {
		...mapGetters('auth', ['productAreaPermission', 'userHasUniversalBrand']),
		statements () {
			if (!this.selectedChannelId) return []
			return this.channels.find(({ channelId }) => channelId === this.selectedChannelId)?.statements.filter(({ liveStatements }) => liveStatements.length > 0) || []
		},
		statementOptions () {
			return this.statements.map(({ statementID, statementText, liveStatements }) => {
				const translatedStatement = liveStatements
					?.find(({ languageId }) => languageId === this.translationLanguageId)
				return {
					value: statementID,
					text: translatedStatement?.statementText ?? statementText
				}
			})
		},
		channelOptions () {
			return this.sortParentAndChildChannels(this.channels)
				.filter(channel => channel.channelType.doubleOptInRelatedChannelID === 0 || channel.channelType.doubleOptInRelatedChannelID === null)
				.map(({ channelId, channelName, channelTranslations, brandName, statements }) => {
					const hasDefaultLiveStatement = statements.filter(statement => {
						return statement.liveStatements.find(({ languageId }) => languageId === 0)
					}).length > 0
					const translatedChannel = channelTranslations
						?.find(({ languageID }) => languageID === this.translationLanguageId)

					if (clientMultiBranded.value) {
						return {
							value: channelId,
							text: `${translatedChannel?.translatedChannelName ?? channelName} - ${brandName} ${!hasDefaultLiveStatement ? '(No Statement configured)' : ''}`,
							disabled: !hasDefaultLiveStatement
						}
					} else {
						return {
							value: channelId,
							text: `${translatedChannel?.translatedChannelName ?? channelName} ${!hasDefaultLiveStatement ? '(No Statement configured)' : ''}`,
							disabled: !hasDefaultLiveStatement
						}
					}
				})
		},
		actionLinkStatementId () {
			return this.actionLinkConfig?.actionLinkStatementId
		},
		actionLinkStatementIsOptIn () {
			return this.actionLinkConfig?.actionLinkStatementIsOptIn
		},
		selectedChannelId () {
			if (this.channelId) return this.channelId
			if (!this.actionLinkStatementId) return null
			const channel = this.channels.find(({ statements }) => statements.some(({ statementID }) => statementID === this.actionLinkStatementId))
			return channel?.channelId
		},
		selectedChannel () {
			return this.channels.find(({ channelId }) => channelId === this.selectedChannelId)
		},
		actionLinkExtendedPreferences () {
			return this.actionLinkConfig?.extendedPreference
				? this.actionLinkConfig?.extendedPreference.map((extendedPref, index) => ({
					...extendedPref,
					index,
					formattedDateCreated: extendedPref.dateCreated ? format(new Date(extendedPref.dateCreated), DATE_FORMAT) : 'NEW: Pending Save'
				}))
				: []
		},
		showTranslations () {
			return this.availableLanguages.length > 1 // More than just Default
		},
		availableLanguages () {
			const channelTranslations = this.selectedChannel?.channelTranslations?.flat() ?? []
			const availableTranslationOptions = []
			const statements = this.selectedChannel?.statements?.flat() ?? []
			const statementTranslations = statements.map(statement => statement.liveStatements).flat()
			// Available Languages are languages that exist both in statement and channel translations
			channelTranslations.forEach(channelTranslation => {
				if (channelTranslation.languageID !== 0) {
					availableTranslationOptions.push({
						value: channelTranslation.languageID,
						text: channelTranslation.languageName
					})
				}
			})
			statementTranslations.forEach(statementTranslation => {
				if (statementTranslation.languageId !== 0) {
					availableTranslationOptions.push({
						value: statementTranslation.languageId,
						text: statementTranslation.languageName
					})
				}
			})
			return [{ text: 'Default', value: 0 }, ...availableTranslationOptions.sort((a, b) =>
				a.text.localeCompare(b.text)
			)]
		},
		userFullPermissions () {
			if (this.brandId === 0 && !this.userHasUniversalBrand) {
				return false
			}
			return this.productAreaPermission(LINKS_AREA_FULL_PERMISSIONS)
		},
		isDoubleOptInChannel () {
			const channel = this.channels.find(channel => channel.channelId === this.selectedChannelId)
			return channel?.channelType?.doubleOptInEligible === true
		}
	},
	async created () {
		const { data: { channels } } = await getPreferenceChannels()
		this.channels = channels
		if (this.brandId !== 0) {
			this.channels = channels.filter(({ brandID }) => brandID === this.brandId || brandID === 0)
		} else {
			this.channels = channels.filter(({ brandID }) => brandID === 0)
		}
	},
	methods: {
		updateStatementId (value) {
			const statement = this.statements.find(({ statementID }) => statementID === value)
			this.updateActionLinkConfig({
				...(this.actionLinkConfig),
				actionLinkStatementId: statement.statementID,
				actionLinkStatementText: statement.statementText,
				actionLinkStatementIsOptIn: statement.isOptIn
			})
		},
		updateExtendedPreferences (value) {
			this.updateActionLinkConfig({
				...(this.actionLinkConfig),
				extendedPreference: value
			})
		},
		toggleDoubleOptIn (value) {
			const selectedChannel = this.channels.find(channel => channel.channelId === this.selectedChannelId)
			let newChannelId

			if (value) {
				const relatedChannel = this.channels.find(channel => channel.channelType.doubleOptInRelatedChannelID === selectedChannel?.channelId)
				newChannelId = relatedChannel?.channelId || this.selectedChannelId
			} else {
				newChannelId = this.selectedChannelId
			}
			this.$emit('update:actionLinkEnforceDoubleOptIn', {
				enforceDoubleOptIn: value,
				actionLinkConfig: {
					...this.actionLinkConfig,
					actionLinkChannelId: newChannelId
				}
			})
		},
		updateActionLinkConfig (value) {
			this.$emit('update:actionLinkConfig', value)
		}
	}
}
</script>
