<template>
	<validation-observer
		#default="{ reset }"
		tag="div"
		class="cassie-vertical-md"
	>
		<SectionCard>
			<template #title>
				Link Fields
				<v-tooltip
					right
				>
					<template #activator="{ on }">
						<v-icon
							right
							v-on="on"
							@click.stop="linkFieldsModalOpen = true"
						>
							mdi-information
						</v-icon>
					</template>
					<span>
						Click to expand
					</span>
				</v-tooltip>
			</template>
			<template #title-action>
				<TextButton
					v-if="userFullPermissions"
					@click="addLinkField"
				>
					<v-icon>
						mdi-plus
					</v-icon>
					Add Field
				</TextButton>
			</template>
			<template #body>
				<p>
					Select the field in Cassie's database that you wish to store information (that will be passed in as part of the Action Link) about the data subject in,
					and whether the information should be used to identify if the data subject already exists in Cassie or not.
				</p>
				<v-simple-table	dense>
					<template #default>
						<thead>
							<tr>
								<th
									scope="col"
									class="text-left"
								>
									Identification Field
								</th>
								<th
									scope="col"
									class="text-left"
								>
									Database Field Name *
								</th>
								<th
									scope="col"
									class="text-left"
								>
									Name in Link *
								</th>
								<th
									scope="col"
									class="text-left"
								>
									Replace value in link *
								</th>
								<th
									scope="col"
									class="text-left"
								>
									Preview value
								</th>
								<th
									scope="col"
									class="text-left"
								>
									Encrypted
								</th>
								<th
									scope="col"
									class="text-left"
								>
									Action
								</th>
							</tr>
						</thead>
						<tbody>
							<tr
								v-for="(linkField, index) in (linkFields || [])"
								:key="linkField.standdakFieldName"
							>
								<td class="text-left">
									<validation-provider
										#default="{ errors }"
										:rules="{
											arrayIsNotEmpty: {
												value: linkFields ? linkFields.filter(({ isMatchField }) => isMatchField) : [],
												ignoreVModel: true
											}
										}"
										mode="passive"
									>
										<v-switch
											:input-value="Boolean(linkField.isMatchField)"
											:error-messages="errors"
											dense
											:hide-details="!errors.length"
											class="ma-0"
											:disabled="!userFullPermissions"
											@change="updateLinkFieldsRow(index, 'isMatchField', $event)"
										/>
									</validation-provider>
								</td>
								<td class="text-left">
									<Dropdown
										:value="linkField.standdakFieldName"
										:items="availableFields"
										custom-sort
										:disabled="!userFullPermissions"
										:label="`Database field name ${index+1}`"
										small
										rules="required"
										@input="updateLinkFieldsRow(index, 'standdakFieldName', $event)"
									/>
								</td>
								<td
									class="text-left"
								>
									<TextField
										:value="linkField.friendlyNameInLink"
										:disabled="!userFullPermissions"
										:label="`Name in link ${index+1}`"
										small
										:rules="{required: true, max: 255, inUseLinkFieldNames: usedNameInLinkFields(linkField)}"
										@input="updateLinkFieldsRow(index, 'friendlyNameInLink', $event)"
									/>
								</td>
								<td
									class="text-left"
								>
									<TextField
										:value="linkField.replaceValueInLink"
										:disabled="!userFullPermissions"
										:label="`Replace value in link ${index+1}`"
										small
										:rules="{required: true, max: 64}"
										@input="updateLinkFieldsRow(index, 'replaceValueInLink', $event)"
									/>
								</td>
								<td>
									<TextField
										:value="linkField.previewValue"
										:disabled="!userFullPermissions"
										:label="`Preview value ${index+1}`"
										small
										:rules="{ max: 255 }"
										@input="updateLinkFieldsRow(index, 'previewValue', $event)"
									/>
								</td>
								<td class="text-left">
									<v-switch
										:input-value="linkField.isEncrypted"
										:disabled="!userFullPermissions"
										dense
										hide-details
										class="ma-0"
										@change="updateLinkFieldsRow(index, 'isEncrypted', $event)"
									/>
								</td>
								<td class="text-left">
									<v-icon
										v-if="linkFields.length > 1 && userFullPermissions"
										small
										@click="onRemoveLinkField(index)"
									>
										mdi-trash-can
									</v-icon>
								</td>
							</tr>
						</tbody>
					</template>
				</v-simple-table>
				<Message
					v-if="showPreviewWarning"
					type="warning"
				>
					Please note that if the preview value matches to a record in Cassie, any submission will update that record.
				</Message>
				<SectionCard
					v-if="showEncryptionKeyCard && userFullPermissions"
					flat
				>
					<template #title>
						Encryption Key
					</template>
					<template #subtitle>
						Select the encryption key that you will use to encrypt the specified fields, so that Cassie is able to decrypt the information when the link is clicked on.
					</template>
					<template #title-action>
						<TextButton
							v-if="userFullPermissions"
							@click="addEncryptionKey"
						>
							<v-icon>
								mdi-plus
							</v-icon>
							Add Encryption Key
						</TextButton>
					</template>
					<template #body>
						<Dropdown
							:value="encryptionKeyId"
							:disabled="!userFullPermissions"
							:items="encryptionKeys.map(key => ({ value: key.encryptionKeyId, text: key.encryptionKeyName }))"
							label="Select an Encryption Key *"
							rules="required"
							class="cassie-input-width-md"
							@input="updateEncryption('encryptionKeyId', $event)"
						/>
					</template>
				</SectionCard>
			</template>
		</SectionCard>
		<SectionCard>
			<template #title>
				Other Config
			</template>
			<template #body>
				<div>
					<Toggle
						:value="createStanddakIfNoMatch"
						:disabled="!userFullPermissions"
						label="Create Data Subject if no match found?"
						tooltip-text="For Links with 'Direct' or 'Provide & Prove' Authentication, this will allow the creation of a new Data Subject if the supplied Authentication & Link fields do not match an existing Data Subject."
						dense
						hide-details
						class="ma-0"
						@update:value="updateConfigurationOptions('createStanddakIfNoMatch', $event)"
					/>
					<Toggle
						:value="multiStandDakMatchIsFailure"
						:disabled="!userFullPermissions"
						label="Treat as failure if link matches to more than one data subject in Cassie"
						tooltip-text="If the supplied Authentication & Link fields match multiple Data Subjects, this option will treat the Link as a failure."
						dense
						hide-details
						class="ma-0"
						@update:value="updateConfigurationOptions('multiStandDakMatchIsFailure', $event)"
					/>
					<Toggle
						:value="limitSubmissionsByIptypeId"
						:disabled="!userFullPermissions"
						label="Limit the number of times a single IP Address can use a link within a set time period"
						tooltip-text="This option allows for limiting to be implemented against the Link.  A maximum number of Preference Page submissions within a given time frame in minutes is required."
						dense
						hide-details
						class="ma-0"
						@update:value="value => {
							updateIpLimitations('limitSubmissionsByIptypeId', value)
							reset()
						}"
					/>
				</div>
				<div
					v-if="limitSubmissionsByIptypeId"
					class="d-flex cassie-horizontal-md"
				>
					<TextField
						:value="noOfSubmissionsAllowedFromIp"
						:disabled="!userFullPermissions"
						class="cassie-input-width-sm"
						label="Number of Allowed Submissions *"
						rules="greaterThanZero"
						type="number"
						@input="updateIpLimitations('noOfSubmissionsAllowedFromIp', Number($event))"
					/>
					<TextField
						:value="iPlimitPeriodInMins"
						:disabled="!userFullPermissions"
						class="cassie-input-width-sm"
						label="Time Period in Minutes *"
						rules="greaterThanZero"
						type="number"
						@input="updateIpLimitations('iPlimitPeriodInMins', Number($event))"
					/>
				</div>
			</template>
		</SectionCard>
		<AddEncryptionKeyModal
			v-if="showAddEncryptionKeyModal"
			@close="showAddEncryptionKeyModal = false"
			@encryption-key-added="reloadLookUpData"
		/>
		<LinkFieldsHelperModal
			v-if="linkFieldsModalOpen"
			@close="linkFieldsModalOpen = false"
		/>
	</validation-observer>
</template>

<script>
import { mapGetters } from 'vuex'
import TextField from '../../../../../shared/components/text-field.vue'
import SectionCard from '../../../../../shared/components/section-card.vue'
import Message from '../../../../../shared/components/message.vue'
import { getStandakFields } from '../../../../../shared/utils/api/configuration.js'
import TextButton from '../../../../../shared/components/text-button.vue'
import Dropdown from '../../../../../shared/components/dropdown.vue'
import AddEncryptionKeyModal from '../../../../../shared/components/add-encryption-key-modal.vue'
import Toggle from '../../../../../shared/components/toggle.vue'
import LinkFieldsHelperModal from './link-fields-helper-modal.vue'
import { showSnackbar } from '../../../../../shared/state/snackbar.js'
import { LINKS_PAGE_FULL_PERMISSIONS, LINKS_AREA_FULL_PERMISSIONS } from '../../../../../shared/permissions/admin-portal-permissions.js'
export default {
	components: { AddEncryptionKeyModal, Dropdown, TextButton, SectionCard, TextField, LinkFieldsHelperModal, Toggle, Message },
	props: {
		configurationOptions: Object,
		linkFields: Array,
		brandId: Number,
		encryptionKeys: Array
	},
	setup () {
		return {
			showSnackbar
		}
	},
	data () {
		return {
			availableFields: [],
			showAddEncryptionKeyModal: false,
			linkFieldsModalOpen: false
		}
	},
	computed: {
		...mapGetters('auth', ['productAreaPermission', 'userHasUniversalBrand']),
		showEncryptionKeyCard () {
			return this.linkFields.some(({ isEncrypted }) => isEncrypted)
		},
		encryptionKeyId () {
			return this.encryption?.encryptionKeyId
		},
		encryption () {
			return this.configurationOptions?.encryption
		},
		createStanddakIfNoMatch () {
			return this.configurationOptions?.createStanddakIfNoMatch
		},
		multiStandDakMatchIsFailure () {
			return this.configurationOptions?.multiStandDakMatchIsFailure
		},
		ipLimitations () {
			return this.configurationOptions?.ipLimitations || {}
		},
		limitSubmissionsByIptypeId () {
			return this.ipLimitations.limitSubmissionsByIptypeId
		},
		noOfSubmissionsAllowedFromIp () {
			return this.ipLimitations.noOfSubmissionsAllowedFromIp
		},
		iPlimitPeriodInMins () {
			return this.ipLimitations.iPlimitPeriodInMins
		},
		userFullPermissions () {
			if (this.brandId === 0 && !this.userHasUniversalBrand) {
				return false
			}
			return this.productAreaPermission(LINKS_AREA_FULL_PERMISSIONS) || this.productAreaPermission(LINKS_PAGE_FULL_PERMISSIONS)
		},
		showPreviewWarning () {
			return this.linkFields.find(
				field => field.previewValue
					? field.previewValue !== ''
					: false
			)
		}
	},
	async created () {
		const { data: availableFields } = await getStandakFields()
		this.availableFields = availableFields.map(({ columnName }) => ({ value: columnName.toLowerCase(), text: columnName }))
	},
	methods: {
		addEncryptionKey () {
			this.showAddEncryptionKeyModal = true
		},
		populateReplaceValue () {
			this.showAddEncryptionKeyModal = true
		},
		updateEncryption (property, value) {
			this.updateConfigurationOptions('encryption', {
				...(this.encryption),
				[property]: value
			})
		},
		updateIpLimitations (property, value) {
			this.updateConfigurationOptions('ipLimitations', {
				...this.ipLimitations,
				[property]: value
			})
		},
		updateConfigurationOptions (property, value) {
			this.$emit('update:configurationOptions', {
				...(this.configurationOptions),
				[property]: value
			})
		},
		updateLinkFieldsRow (index, property, value) {
			const linkFields = JSON.parse(JSON.stringify(this.linkFields))
			const duplicateLinkField = linkFields.find(({ standdakFieldName }) => standdakFieldName === value && property === 'standdakFieldName')
			if (!duplicateLinkField) {
				linkFields[index][property] = value
				if (property === 'standdakFieldName') {
					linkFields[index].replaceValueInLink = `{${value}}`
				}
				this.updateLinkFields(linkFields)
			} else {
				if (linkFields[index][property] === value) {
					return
				}
				this.showSnackbar({ text: `${value} already exists for this Link - Please select another`, color: 'red' })
				this.onRemoveLinkField(index)
			}
		},
		onRemoveLinkField (index) {
			const linkFields = this.linkFields.filter((_, i) => i !== index)
			this.updateLinkFields(linkFields)
		},
		addLinkField () {
			this.updateLinkFields([...(this.linkFields), {}])
		},
		updateLinkFields (linkFields) {
			this.$emit('update:linkFields', linkFields)
		},
		reloadLookUpData () {
			this.$emit('reload-look-up-data')
		},
		usedNameInLinkFields (linkField) {
			const linkFields = this.linkFields?.map(({ friendlyNameInLink, standdakFieldName }) => ({ friendlyNameInLink, standdakFieldName }))
			return linkFields.filter(({ friendlyNameInLink, standdakFieldName }) =>
				friendlyNameInLink === linkField.friendlyNameInLink && standdakFieldName !== linkField.standdakFieldName).map(({ friendlyNameInLink }) => friendlyNameInLink)
				.join(',')
		}
	}
}
</script>
