<template>
	<SectionCard>
		<template #title>
			Outcomes
		</template>
		<template #body>
			<p v-if="linkType === linkTypes.actionLink">
				Select where you would like the end user to be taken once they have used the link.
			</p>
			<p v-else>
				Select where you would like the end user to be taken once they have submitted data.
			</p>
			<Dropdown
				:value="confirmationPageTypeId"
				:items="successOutcomeOptions"
				:disabled="!userFullPermissions"
				:tooltip-text="successfulOutcomeTooltip"
				item-value="value"
				label="Successful Outcome *"
				rules="required"
				class="cassie-input-width-md"
				@input="updateSuccessOutcome('confirmationPageTypeId', $event)"
			/>
			<!-- confirmationPageTypeId -->
			<Dropdown
				v-if="confirmationPageTypeId === CONFIRMATION_PAGE_OUTCOME"
				:value="internalConfirmationPageId"
				:items="brandFilteredConfirmationPages.map(({ confirmationPageId, confirmationPageName }) => ({ value: confirmationPageId, text: confirmationPageName }))"
				:disabled="!userFullPermissions"
				label="Select Confirmation Page *"
				rules="required"
				class="cassie-input-width-md"
				reserve-tooltip-space
				@input="updateSuccessOutcome('internalConfirmationPageId', $event)"
			/>
			<TextField
				v-if="confirmationPageTypeId === URL_OUTCOME"
				:value="externalConfirmationPageUrl"
				:disabled="!userFullPermissions"
				label="Select Destination URL *"
				:rules="{required: true, isUrlwithHttp: true, max: 512}"
				class="cassie-input-width-md"
				reserve-tooltip-space
				@input="updateSuccessOutcome('externalConfirmationPageUrl', $event)"
			/>
			<Dropdown
				v-model="selectedFailureOutcomeOption"
				:items="failureOutcomeOptions"
				:disabled="!userFullPermissions"
				item-value="value"
				label="Failure Outcome *"
				:tooltip-text="failureOutcomeTooltip"
				rules="required"
				class="cassie-input-width-md"
				@input="$emit('update:failureType', $event)"
			/>
			<TextField
				v-if="selectedFailureOutcomeOption === FAILURE_PAGE_OUTCOME"
				:disabled="!userFullPermissions"
				label="Failure Page *"
				value="Default Failure Page"
				view-only
				reserve-tooltip-space
				rules="required"
				class="cassie-input-width-md"
			/>
			<TextField
				v-if="selectedFailureOutcomeOption === URL_OUTCOME"
				:disabled="!userFullPermissions"
				:value="customErrorUrl"
				label="Failure Destination URL *"
				class="cassie-input-width-md"
				reserve-tooltip-space
				:rules="{required: true, isUrlwithHttp: true, max: 512}"
				@input="$emit('update:customErrorUrl', $event)"
			/>
		</template>
	</SectionCard>
</template>
<script>
import { mapGetters } from 'vuex'
import SectionCard from '../../../../../shared/components/section-card.vue'
import Dropdown from '../../../../../shared/components/dropdown.vue'
import TextField from '../../../../../shared/components/text-field.vue'
import { LINKS_AREA_FULL_PERMISSIONS } from '../../../../../shared/permissions/admin-portal-permissions.js'
import { linkTypes } from './preference-links/link-types.js'

const CONFIRMATION_PAGE_OUTCOME = 2
const FAILURE_PAGE_OUTCOME = 2
const FAILURE_URL = 'Forward To URL'
const URL_OUTCOME = 1
const REMAIN_ON_PAGE_OUTCOME = 3 // TBC

export default {
	components: { TextField, Dropdown, SectionCard },
	props: {
		confirmationPages: Array,
		configurationOptions: Object,
		customErrorUrl: String,
		brandId: Number,
		linkType: Number
	},
	setup () {
		return {
			linkTypes
		}
	},
	data () {
		return {
			CONFIRMATION_PAGE_OUTCOME,
			URL_OUTCOME,
			FAILURE_PAGE_OUTCOME,
			FAILURE_URL,
			selectedSuccessOutcomeOption: null,
			selectedFailureOutcomeOption: null,
			failureOutcomeItems: [FAILURE_PAGE_OUTCOME, FAILURE_URL],
			testDropdownVal: null
		}
	},
	computed: {
		...mapGetters('auth', ['productAreaPermission', 'userHasUniversalBrand']),
		successOutcomeOptions () {
			return [
				{ value: CONFIRMATION_PAGE_OUTCOME, text: 'Forward to Confirmation Page' },
				{ value: URL_OUTCOME, text: 'Forward to URL' }
			]
		},
		failureOutcomeOptions () {
			return [
				{ value: FAILURE_PAGE_OUTCOME, text: 'Forward to Failure Page' },
				{ value: URL_OUTCOME, text: 'Forward to URL' }
			]
		},
		brandFilteredConfirmationPages () {
			if (!this.confirmationPages) {
				return []
			}
			return this.confirmationPages.filter(({ brandId }) => brandId === 0 || brandId === this.brandId)
		},
		successOutcome () {
			return this.configurationOptions?.successOutcome || {}
		},
		confirmationPageTypeId () {
			return this.successOutcome?.confirmationPageTypeId
		},
		externalConfirmationPageUrl () {
			return this.successOutcome?.externalConfirmationPageUrl
		},
		internalConfirmationPageId () {
			return this.successOutcome?.internalConfirmationPageId
		},
		userFullPermissions () {
			if (this.brandId === 0 && !this.userHasUniversalBrand) {
				return false
			}
			return this.productAreaPermission(LINKS_AREA_FULL_PERMISSIONS)
		},
		successfulOutcomeTooltip () {
			switch (this.confirmationPageTypeId) {
				case CONFIRMATION_PAGE_OUTCOME:
					return 'Following a successful Preference update, the end user will be redirected to the chosen Confirmation Page'
				case URL_OUTCOME:
					return 'Following a successful Preference update, the end user will be redirected to the Destination URL specified.  Note this must begin with http:// or https://'
				case REMAIN_ON_PAGE_OUTCOME:
					return `Following a successful Preference update the end user will remain on the page and receive an optional Preference Updated 'success' message confirming the submission has been received.`
				default:
					return 'Select the type of response if the Link update is successful'
			}
		},
		failureOutcomeTooltip () {
			switch (this.selectedFailureOutcomeOption) {
				case FAILURE_PAGE_OUTCOME:
					return 'If the update is unsuccessful, the end user will be redirected to a Cassie default error page'
				case URL_OUTCOME:
					return 'If the update is unsuccessful, the end user will be redirected to the Destination URL specified.  Note this must begin with http:// or https://'
				case REMAIN_ON_PAGE_OUTCOME:
					return 'If the update is unsuccessful, the end user will remain on the page and receiving a plain text failure message.'
				default:
					return 'Select the type of response if the Link update fails'
			}
		}
	},
	mounted () {
		if (this.confirmationPageTypeId) {
			this.selectedSuccessOutcomeOption = CONFIRMATION_PAGE_OUTCOME
		} else if (this.externalConfirmationPageUrl) {
			this.selectedSuccessOutcomeOption = URL_OUTCOME
		}

		if (this.customErrorUrl) {
			this.selectedFailureOutcomeOption = URL_OUTCOME
		} else {
			this.selectedFailureOutcomeOption = FAILURE_PAGE_OUTCOME
		}
	},
	methods: {
		async clearOutcomes () {
			this.updateSuccessOutcome('confirmationPageTypeId', null)
			await this.$nextTick()
			this.updateSuccessOutcome('externalConfirmationPageUrl', null)
			await this.$nextTick()
		},
		updateSuccessOutcome (property, value) {
			this.updateConfigurationOptions('successOutcome', {
				...this.successOutcome,
				[property]: value
			})
		},
		updateConfigurationOptions (property, value) {
			this.$emit('update:configurationOptions', {
				...(this.configurationOptions),
				[property]: value
			})
		}
	}
}
</script>
