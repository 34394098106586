<template>
	<Modal width="800px">
		<template #modal-title>
			Personal Information Fields
			<v-spacer />
			<IconButton @click="$emit('close')">
				mdi-close
			</IconButton>
		</template>
		<template #modal-content>
			<p>
				Enabling the Personal Information Fields option will allow you to add fields to a Link to allow a Data Subject to view or update personal information
			</p>
			<p>
				<b>
					Display Name
				</b> - This is the how the chosen field will be labelled on the Preference Page
			</p>
			<p>
				<b>
					Read Only
				</b> - Setting this option on will display the PII data, but the Data Subject will not be permitted to update it
			</p>
			<p>
				<b>
					Mandatory
				</b> - Setting the field as mandatory will force the Data Subject to input data in to that field before making a submission
			</p>
			<p>
				<b>
					Display Order
				</b> - The order in which the PII fields will be displayed to the Data Subject on the Preference Page
			</p>
			<p>
				<b>
					Action
				</b> - The Action section gives the ability to delete a field, alter the display order or revert the Display Name to the original value held in Cassie
			</p>
		</template>
		<template #modal-footer>
			<v-spacer />
			<SecondaryActionButton @click="$emit('close')">
				Close
			</SecondaryActionButton>
		</template>
	</Modal>
</template>

<script>
import Modal from '../../../../../../shared/components/modal.vue'
import IconButton from '../../../../../../shared/components/icon-button.vue'
import SecondaryActionButton from '../../../../../../shared/components/secondary-action-button.vue'
export default {
	components: {
		Modal,
		IconButton,
		SecondaryActionButton
	}
}
</script>
