<template>
	<SectionCard>
		<template #title>
			Preferences
		</template>
		<template #title-action>
			<Dropdown
				v-if="showTranslations"
				v-model="selectedLanguageId"
				:items="availableLanguages"
				custom-sort
				label="Show available translations in:"
			/>
		</template>
		<template #body>
			<v-row dense>
				<v-col
					cols="6"
				>
					<!-- note the space after * is needed for Vee validate (not sure why) -->
					<Dropdown
						v-model="selectedChannelId"
						:items="channelOptions"
						:disabled="!userFullPermissions"
						item-disabled="disabled"
						tooltip-text="Select the Cassie Channel to add to this Preference Page"
						:label="'Channel * ' | useLabels"
						custom-sort
						@input="selectedStatementId = null"
					/>
				</v-col>
				<v-col
					cols="6"
				>
					<Dropdown
						v-model="selectedStatementId"
						:items="statementOptions"
						:disabled="!selectedChannelId && !userFullPermissions"
						:label="'Statement Text *' | useLabels"
						tooltip-text="Select one of the Statements associated to the Channel to display on this Preference Page
Preference Area"
					/>
				</v-col>
			</v-row>
			<v-row
				dense
				class="align-center"
			>
				<v-col
					cols="6"
				>
					<Dropdown
						v-model="selectedPreferenceAreaId"
						:items="preferenceAreaOptions"
						:disabled="!userFullPermissions"
						label="Preference Area *"
						tooltip-text="Select the Area of the Preference Page where this Statement will be displayed"
					/>
				</v-col>
				<v-col
					cols="3"
				>
					<div class="d-flex pl-1 pb-1">
						<Toggle
							v-if="isDoubleOptInChannel"
							label="Enforce Double Opt In?"
							tooltip-text="Enabling this functionality will require a double opt-in confirmation via email."
							@update:value="(value) => toggleDoubleOptIn(value, selectedStatementId)"
						/>
					</div>
				</v-col>
				<v-col
					cols="3"
				>
					<div class="d-flex">
						<v-spacer />
						<PrimaryActionButton
							:disabled="addDisabled || !userFullPermissions"
							@click="addPagePreference"
						>
							Add
						</PrimaryActionButton>
					</div>
				</v-col>
			</v-row>
			<DataTable
				:items="translatedPreferenceList"
				:headers="tableHeaders"
				:disable-sort="true"
			>
				<template #item.preferencesAreaDescription="{ item }">
					{{ preferenceAreaName(item) }}
				</template>
				<template #item.enforceDoubleOptIn="{ item }">
					<v-icon small>
						{{ getIsDoubleOptIn(item) ? 'mdi-check' : 'mdi-close' }}
					</v-icon>
				</template>
				<template #item.action="{ index }">
					<IconButton
						v-if="index !== 0 && userFullPermissions"
						@click="movePreferenceUp(index)"
					>
						mdi-arrow-up
					</IconButton>
					<IconButton
						v-if="index !== pagePreferences.length - 1 && userFullPermissions"
						@click="movePreferenceDown(index)"
					>
						mdi-arrow-down
					</IconButton>
					<IconButton
						v-if="userFullPermissions"
						tooltip-text="Remove Preference"
						@click="removePreference(index)"
					>
						mdi-trash-can
					</IconButton>
				</template>
			</DataTable>
		</template>
	</SectionCard>
</template>

<script>
import { mapGetters } from 'vuex'
import SectionCard from '../../../../../shared/components/section-card.vue'
import PrimaryActionButton from '../../../../../shared/components/primary-action-button.vue'
import Dropdown from '../../../../../shared/components/dropdown.vue'
import Toggle from '../../../../../shared/components/toggle.vue'
import DataTable from '../../../../../shared/components/data-table.vue'
import IconButton from '../../../../../shared/components/icon-button.vue'
import sortParentAndChildChannelsMixin from '../../../../../shared/mixins/sort-parent-and-child-channels.js'
import { getPreferenceChannels } from '../../../../../shared/utils/api/channels.js'
import { SEE_LINKS_AREA, LINKS_AREA_FULL_PERMISSIONS, LINKS_PAGE_FULL_PERMISSIONS } from '../../../../../shared/permissions/admin-portal-permissions.js'
import { clientMultiBranded } from '../../../../../shared/state/brands.js'
import { useLabels } from '../../../../../shared/state/admin-portal-navigation.js'
const MAIN_AREA_ID = 0
const SUB_AREA_1_ID = 1
const SUB_AREA_2_ID = 2
export default {
	components: { IconButton, DataTable, Dropdown, Toggle, PrimaryActionButton, SectionCard },
	mixins: [sortParentAndChildChannelsMixin],
	props: {
		pageContents: Object,
		pagePreferences: Array,
		brandId: Number
	},
	setup () {
		return {
			clientMultiBranded
		}
	},
	data () {
		return {
			selectedChannelId: null,
			selectedStatementId: null,
			selectedPreferenceAreaId: null,
			showChannelName: true,
			selectedLanguageId: 0,
			channels: [],
			MAIN_AREA_ID,
			SUB_AREA_1_ID,
			SUB_AREA_2_ID,
			enforceDoubleOptIn: {}
		}
	},
	computed: {
		...mapGetters('auth', ['productAreaPermission', 'userHasUniversalBrand']),
		tableHeaders () {
			return [
				{ value: 'statementId', text: 'ID', width: '8%' },
				{ value: 'statementText', text: useLabels('Statement Text'), width: '38%' },
				{ value: 'channelName', text: useLabels('Channel'), width: '18%' },
				{ value: 'preferencesAreaDescription', text: 'Preference Area', width: '16%' },
				{ value: 'enforceDoubleOptIn', text: 'Double Opt In?', width: '10%' },
				{ value: 'action', text: 'Action', width: '10%' }
			]
		},
		addDisabled () {
			return this.selectedChannelId === null ||
				this.selectedStatementId === null ||
				this.selectedPreferenceAreaId === null ||
				this.statementOptions?.length === 0
		},
		statementToggles () {
			return this.pagePreferences.reduce((acc, preference) => {
				acc[preference.statementId] = preference.enforceDoubleOptIn
				return acc
			}, {})
		},
		showTranslations () {
			return this.availableLanguages.length > 1 // More than just Default
		},
		availableLanguages () {
			const selectedChannels = this.pagePreferences.map(({ channelId }) => channelId)
			const selectedStatements = this.pagePreferences.map(({ statementId }) => statementId)
			let channelTranslations = this.channels?.map(channel => ({
				...channel
			}))
			channelTranslations = channelTranslations.filter(({ channelTranslations, channelId }) =>
				channelTranslations.length >= 1 && selectedChannels.includes(channelId)).map(channel => {
				return channel.channelTranslations
			}).flat()
			const availableTranslationOptions = []
			const statements = this.channels?.map(x => x.statements).flat().filter(({ statementID }) => selectedStatements.includes(statementID)) ?? []
			const statementTranslations = statements.map(statement => statement.liveStatements).flat()
			if (channelTranslations === []) {
				return availableTranslationOptions
			}
			channelTranslations.forEach(channelTranslation => {
				if (channelTranslation.languageID) {
					availableTranslationOptions.push({
						...channelTranslation,
						value: channelTranslation.languageID,
						text: channelTranslation.languageName
					})
				}
			})
			statementTranslations.forEach(statementTranslation => {
				if (statementTranslation?.languageId) {
					availableTranslationOptions.push({
						...statementTranslation,
						value: statementTranslation.languageId,
						text: statementTranslation.languageName
					})
				}
			})
			return [{ value: 0, text: 'Default' }, ...availableTranslationOptions.sort((a, b) =>
				a.text.localeCompare(b.text)
			)]
		},
		channelOptions () {
			return this.sortParentAndChildChannels(this.channels)
				.filter(channel => channel.channelType.doubleOptInRelatedChannelID === 0 || channel.channelType.doubleOptInRelatedChannelID === null)
				.map(({ channelId, channelName, channelTranslations, brandName, statements }) => {
					const hasDefaultLiveStatement = statements.filter(statement => {
						return statement.liveStatements.find(({ languageId }) => languageId === 0)
					}).length > 0
					let translatedChannel = []
					if (channelTranslations !== []) {
						translatedChannel = channelTranslations?.find(({ languageID }) => languageID === this.selectedLanguageId)
					}
					if (clientMultiBranded.value) {
						return {
							value: channelId,
							text: `${translatedChannel?.translatedChannelName ?? channelName} - ${brandName}
							${!hasDefaultLiveStatement ? '(No Statement configured)' : ''}`,
							disabled: !hasDefaultLiveStatement
						}
					} else {
						return {
							value: channelId,
							text: `${translatedChannel?.translatedChannelName ?? channelName} ${!hasDefaultLiveStatement ? '(No Statement configured)' : ''}`,
							disabled: !hasDefaultLiveStatement
						}
					}
				}).filter(({ value }) => !this.selectedChannelIds.includes(value))
		},
		statements () {
			if (!this.selectedChannelId) return []
			return this.channels.find(({ channelId }) => channelId === this.selectedChannelId)?.statements || []
		},
		statementOptions () {
			const allStatements = this.statements
			const selectedStatements = this.pagePreferences.map(({ statementId, statementText }) => ({ value: statementId, text: statementText }))
			const filteredStatements = allStatements.filter(x => !selectedStatements.some(y => y.value === x.statementID)).filter(({ liveStatements }) => liveStatements.length > 0)
			const result = filteredStatements.map(({ statementID, statementText, liveStatements }) => {
				const translatedStatement = liveStatements
					?.find(({ languageId }) => languageId === this.selectedLanguageId)
				return {
					value: statementID,
					text: translatedStatement?.statementText ?? statementText
				}
			})
			return result
		},
		translatedPreferenceList () {
			return this.pagePreferences.map(
				({ statementId, statementText, channelId, channelName, preferencesAreaDescription, preferencesAreaId, showChannelName, enforceDoubleOptIn }) => {
					const translatedStatement = this.channels?.find(x => x.channelId === channelId)?.statements.find(({ statementID }) => statementID === statementId)?.liveStatements
						?.find(({ languageId }) => languageId === this.selectedLanguageId)
					const channelTranslations = this.channels.find(c => c.channelId === channelId)?.channelTranslations ?? []
					const translatedChannel = channelTranslations?.find(({ languageID }) => languageID === this.selectedLanguageId)
					return {
						statementId: statementId,
						statementText: translatedStatement?.statementText ?? statementText,
						channelId: channelId,
						channelName: translatedChannel?.translatedChannelName ?? channelName,
						preferencesAreaDescription: preferencesAreaDescription,
						preferencesAreaId: preferencesAreaId,
						showChannelName: showChannelName,
						enforceDoubleOptIn: enforceDoubleOptIn
					}
				})
		},
		preferenceAreaOptions () {
			const options = [{ value: 0, text: 'Main Area' }]
			if (this.pageContents?.subPreferenceArea1title) options.push({ value: 1, text: this.pageContents.subPreferenceArea1title })
			if (this.pageContents?.subPreferenceArea2title) options.push({ value: 2, text: this.pageContents.subPreferenceArea2title })
			return options
		},
		userFullPermissions () {
			if (this.brandId === 0 && !this.userHasUniversalBrand) {
				return false
			}
			return this.productAreaPermission(LINKS_AREA_FULL_PERMISSIONS) || this.productAreaPermission(LINKS_PAGE_FULL_PERMISSIONS)
		},
		userReadOnly () {
			return this.productAreaPermission(SEE_LINKS_AREA)
		},
		selectedChannelIds () {
			return this.pagePreferences.map(({ channelId }) => channelId)
		},
		isDoubleOptInChannel () {
			const channel = this.channels.find(channel => channel.channelId === this.selectedChannelId)
			return channel?.channelType?.doubleOptInEligible === true
		}
	},
	async created () {
		const { data: { channels } } = await getPreferenceChannels()
		if (this.brandId !== 0) {
			this.channels = channels.filter(({ brandID }) => brandID === this.brandId || brandID === 0)
		} else {
			this.channels = channels.filter(({ brandID }) => brandID === 0)
		}
		this.updatePagePreferences(this.applySort(this.pagePreferences))
	},
	methods: {
		applySort (array) {
			return array.sort((a, b) => a.preferencesAreaId - b.preferencesAreaId)
		},
		move (array, from, to) {
			const arrayCopy = JSON.parse(JSON.stringify(array))
			arrayCopy.splice(to, 0, arrayCopy.splice(from, 1)[0])
			return this.applySort(arrayCopy)
		},
		removePreference (index) {
			const preferences = [...this.pagePreferences]
			this.$delete(preferences, index)
			this.updatePagePreferences(preferences)
		},
		movePreferenceDown (index) {
			this.updatePagePreferences(this.move(this.pagePreferences, index, index + 1))
		},
		movePreferenceUp (index) {
			this.updatePagePreferences(this.move(this.pagePreferences, index, index - 1))
		},
		updatePagePreferences (pagePreferences) {
			this.$emit('update:pagePreferences', pagePreferences)
		},
		addPagePreference () {
			const selectedStatementId = this.selectedStatementId
			if (this.enforceDoubleOptIn[selectedStatementId] === undefined) {
				this.$set(this.enforceDoubleOptIn, selectedStatementId, false)
			}
			let selectedChannel = this.channels.find(({ channelId }) => channelId === this.selectedChannelId)
			let newChannelId = this.selectedChannelId
			if (this.enforceDoubleOptIn[selectedStatementId]) {
				const relatedChannel = this.channels.find(
					({ channelType }) => channelType.doubleOptInRelatedChannelID === selectedChannel?.channelId
				)
				newChannelId = relatedChannel?.channelId || this.selectedChannelId
				selectedChannel = relatedChannel || selectedChannel
			}

			const statementText = this.statementOptions.find(({ value }) => value === selectedStatementId)?.text
			const channelName = selectedChannel?.channelName?.replace('\u2514', '').trim()
			const preferencesAreaDescription = this.preferenceAreaOptions.find(({ value }) => value === this.selectedPreferenceAreaId)?.text

			this.updatePagePreferences(this.applySort([
				...(this.pagePreferences),
				{
					statementId: selectedStatementId,
					preferencesAreaId: this.selectedPreferenceAreaId,
					showChannelName: this.showChannelName,
					channelId: newChannelId,
					statementText,
					channelName,
					preferencesAreaDescription,
					enforceDoubleOptIn: this.enforceDoubleOptIn[selectedStatementId]
				}
			]))

			this.selectedChannelId = null
		},

		preferenceAreaName (preference) {
			if (preference.preferencesAreaId === this.MAIN_AREA_ID) return this.pageContents?.mainPreferencesAreaTitle
			else if (preference.preferencesAreaId === this.SUB_AREA_1_ID) return this.pageContents?.subPreferenceArea1title
			else if (preference.preferencesAreaId === this.SUB_AREA_2_ID) return this.pageContents?.subPreferenceArea2title
		},
		toggleDoubleOptIn (value, statementId) {
			this.$set(this.enforceDoubleOptIn, statementId, value)
		},
		getIsDoubleOptIn (preference) {
			return preference.enforceDoubleOptIn
		}
	}
}
</script>
