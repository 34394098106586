<template>
	<SectionCard>
		<template #title>
			Theme
		</template>
		<template #title-action />
		<template #body>
			<div>
				Select the theme that you wish to apply to the {{ linkType === linkTypes.actionLink ? 'Action Link' : 'Preference Page' }}
			</div>
			<Dropdown
				:value="linkThemeId"
				:label="`Theme ${disableThemeField ? '' : '*'}`"
				:items="themes"
				:disabled="disableThemeField || !userFullPermissions"
				class="cassie-input-width-md"
				:rules="{required: !disableThemeField}"
				@change="$emit('update:themeId', $event)"
			/>
			<Message
				v-if="disableThemeField"
				type="info"
				class="cassie-input-width-md"
			>
				When using direct authentication method, you do not need to have a theme selected.
			</Message>
		</template>
	</SectionCard>
</template>
<script>
import { mapGetters } from 'vuex'
import { getThemes } from '../../../../../shared/utils/api/themes.js'
import Dropdown from '../../../../../shared/components/dropdown.vue'
import SectionCard from '../../../../../shared/components/section-card.vue'
import Message from '../../../../../shared/components/message.vue'
import { linkTypes } from './preference-links/link-types.js'
import { LINKS_AREA_FULL_PERMISSIONS } from '../../../../../shared/permissions/admin-portal-permissions.js'
export default {
	components: { SectionCard, Dropdown, Message },
	props: {
		linkBrandId: {
			type: Number,
			required: false
		},
		linkThemeId: {
			type: Number,
			required: false
		},
		authType: {
			type: Number,
			required: true
		},
		linkType: {
			type: Number,
			required: true
		}
	},
	setup () {
		return {
			linkTypes
		}
	},
	data () {
		return {
			themes: []
		}
	},
	computed: {
		...mapGetters('auth', ['productAreaPermission']),
		directAuthType () {
			return this.authType === 1
		},
		mappedThemes () {
			return this.themes.map(theme => ({
				...theme,
				value: theme.themeId,
				text: theme.themeName
			}))
		},
		disableThemeField () {
			return this.linkType === linkTypes.actionLink && this.directAuthType
		},
		userFullPermissions () {
			return this.productAreaPermission(LINKS_AREA_FULL_PERMISSIONS)
		}
	},

	async created () {
		await this.loadThemes()
	},
	methods: {
		async loadThemes () {
			const { data: { themes } } = await getThemes()
			this.themes = themes.map(theme => {
				return {
					...theme,
					value: theme.themeId,
					text: theme.themeName
				}
			})
		}
	}
}
</script>
